<template>
  <div class="publications-view">
    <div class="list-items">
      <div class="list-items-header">
        <div>
          <CiHeading level="1">
            {{ selectedListsLength || "" }} {{ $t("publications.header") }}
          </CiHeading>
          <div v-if="anySelectedList && vehicles.length">
            {{ vehiclesCount }} {{ $t("publications.vehicles-listed") }}
          </div>
        </div>
        <div class="list-items-filters">
          <CiSort :saved-state="sortSettings" @change="sortChange" />
          <CiListFilter v-model="selectedLists" :options="getListings" />
        </div>
      </div>

      <CiEmptyState
        v-if="!anyListings && !loading"
        data-cy="ci-list__empty-state"
      >
        <template #header>
          {{ $t("publications.lists-not-exist.header") }}
        </template>
        <template #description>
          <p>{{ $t("publications.lists-not-exist.description") }}</p>
          <br />
          <RdxButton
            class="ci-list__empty-state-button"
            :outline="true"
            :to="{ name: 'CarsInventoryNewList' }"
          >
            <RdxIcon icon="ri-add-line" />
            {{ $t("publications.create-list-button") }}
          </RdxButton>
        </template>
      </CiEmptyState>
      <CiEmptyState
        v-else-if="anyListings && !anySelectedList && !loading"
        class="ci-publications__empty-state--margin"
        data-cy="ci-list__empty-state"
      >
        <template #header>
          {{ $t("publications.lists-no-selected.header") }}
        </template>
        <template #description>
          {{ $t("publications.lists-no-selected.description") }}
        </template>
      </CiEmptyState>
      <template v-else>
        <div class="ci-publications__header">
          <div class="ci-listing-item--spacer" />
          <div
            v-for="(list, id) in selectedLists"
            :key="`${list.title}-${id}`"
            class="ci-listing-item"
          >
            <span class="ci-listing-item__name" @click="editListing(list.id)">
              {{ list.attributes.title }}
            </span>
            <span class="ci-listing-item__company">
              {{ list.attributes.owner_name }}
            </span>
          </div>
        </div>
        <div class="ci-publications__content">
          <CiEmptyState
            v-if="!vehicles.length && userIsSearchingForVin"
            class="ci-publications__empty-state--fixed-width"
          >
            <template #header>
              {{ $t("global.vin-empty-state.header") }}
            </template>
            <template #description>
              <p class="pt-1 mb-3">
                {{ $t("global.vin-empty-state.description") }}
              </p>
            </template>
          </CiEmptyState>
          <CiEmptyState
            v-else-if="!vehicles.length && !loading"
            class="ci-publications__empty-state--fixed-width"
            data-cy="ci-list__empty-state"
          >
            <template #header>
              {{ $t("publications.empty-state.header") }}
            </template>
            <template #description>
              <p class="pt-1 mb-3">
                {{ $t("publications.empty-state.description") }}
              </p>
              <portal-target name="publications-filters-empty-state" />
            </template>
          </CiEmptyState>
          <div class="ci-vehicle-items">
            <CiPublicationRow
              v-for="vehicle of vehicles"
              :key="`vehicle-${vehicle.id}`"
              :vehicle-id="parseInt(vehicle.id)"
              :brand-name="vehicle.nameplate_brand_name"
              :nameplate-name="vehicle.nameplate_name"
              :vin="vehicle.vin"
              :version="vehicle.version"
              :state="vehicle.state"
              :production-year="vehicle.production_year"
              :drivetrain="vehicle.drivetrain"
              :sale-price="vehicle.sale_price"
              :price="vehicle.msrp_price"
              :lease="vehicle.monthly_price"
              :price-hidden="vehicle.price_hidden"
              :selected-lists="selectedLists"
              :cover-photo="vehicle.cover_photo_small"
              :currency="vehicle.currency"
              :owner-locale="vehicle.owner_locale"
              @highlight="highlight = $event"
              @open-preview="openVehiclePreview(vehicle.id)"
            />
          </div>
          <InfiniteLoading
            :identifier="queryString"
            force-use-infinite-wrapper=".cars-inventory-view__inner"
            @infinite="getVehicles"
          >
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </InfiniteLoading>
        </div>
      </template>
    </div>

    <portal to="cars-inventory-nav">
      <RdxButton filled :to="{ name: 'CarsInventoryNewList' }">
        <RdxIcon icon="ri-add-line mr-1" />
        {{ $t("publications.create-list-button") }}
      </RdxButton>
    </portal>
    <component :is="'style'" v-if="inlineCss">{{ inlineCss }}</component>
    <CiVehiclePreview
      v-if="selectedVehicleId"
      :vehicle-id="selectedVehicleId"
      @close-modal="closeVehiclePreview"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import { RdxIcon, RdxButton } from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiHeading from "@/components/atoms/CiHeading";
import CiPublicationRow from "@/components/molecules/CiPublicationRow";
import CiListFilter from "@/components/molecules/CiListFilter";
import CiSort from "@/components/CiSort/CiSort";
import CiEmptyState from "@/components/molecules/CiEmptyState";
import CiVehiclePreview from "@/components/CiVehiclePreview/CiVehiclePreview";
import { deattribute } from "kitsu-core";

export default {
  name: "PublicationsView",

  components: {
    InfiniteLoading,
    RdxIcon,
    RdxButton,
    CiHeading,
    CiPublicationRow,
    CiListFilter,
    CiSort,
    CiEmptyState,
    CiVehiclePreview
  },

  data() {
    return {
      page: 1,
      itemsPerPage: 5,
      userListings: [],
      vehicles: [],
      vehiclesCount: 0,
      highlight: null,
      selectedVehicleId: 0,
      loading: true
    };
  },

  computed: {
    inlineCss() {
      // eslint-disable-next-line
      const css = `.ci-publication-row .ci-list-table__column.column-${this.highlight}{background:#fafafa;}`;
      return this.highlight !== null ? css : "";
    },

    selectedLists: {
      get() {
        return this.userListings;
      },
      set(value) {
        this.userListings = value;
        let currentUuid = window.localStorage.getItem("company_uuid");
        let storedData = window.localStorage.getItem("user-listings-ids");
        let dataToStore = storedData ? JSON.parse(storedData) : {};
        dataToStore[currentUuid] = this.userListings.map(({ id }) => id);
        dataToStore = JSON.stringify(dataToStore);
        localStorage.setItem("user-listings-ids", dataToStore);
      }
    },

    selectedListsLength() {
      return this.selectedLists.length;
    },
    anySelectedList() {
      return Boolean(this.selectedListsLength);
    },
    anyListings() {
      return Boolean(this.getListings.length);
    },
    getListings() {
      return this.$store.getters["listings/getAll"] || [];
    },
    userIsSearchingForVin() {
      return !!this.vinNumber;
    },
    ...mapGetters({
      queryString: "sortFilter/string",
      sortSettings: "sortFilter/sortSetting",
      vinNumber: "sortFilter/vinNumber"
    })
  },

  watch: {
    queryString() {
      this.page = 1;
      this.vehicles = [];
    }
  },

  async mounted() {
    await this.$store.dispatch("listings/fetchAll");
    this.preselectUserListings();
  },

  methods: {
    getVehicles($state) {
      this.$http
        .get(
          "vehicles?items=" +
            this.itemsPerPage +
            "&page=" +
            this.page +
            this.queryString
        )
        .then(response => {
          let listingsCount = Object.keys(response.data.data).length;
          if (listingsCount) {
            deattribute(response.data.data);
            this.vehiclesCount = response.data.meta.pagination.count;
            this.page += 1;
            this.vehicles.push(...response.data.data);
            if ($state) {
              listingsCount === this.itemsPerPage
                ? $state.loaded()
                : $state.complete();
            }
          } else {
            if ($state) $state.complete();
          }
          this.loading = false;
        });
    },

    editListing(id) {
      this.$router.push({
        name: "CarsInventoryEditList",
        params: { id }
      });
    },

    preselectUserListings() {
      let userListingsIds;
      let storedData = window.localStorage.getItem("user-listings-ids");
      if (this.isJsonString(storedData)) {
        let currentUuid = window.localStorage.getItem("company_uuid");
        if (typeof JSON.parse(storedData) === "object") {
          userListingsIds = JSON.parse(storedData)[currentUuid];
        } else {
          localStorage.removeItem("user-listings-ids");
        }
      } else {
        localStorage.removeItem("user-listings-ids");
      }
      if (Array.isArray(userListingsIds)) {
        this.selectedLists = this.getListings.filter(listingEntrie => {
          return userListingsIds.includes(listingEntrie.id);
        });
      } else {
        this.selectedLists = this.getListings;
      }
    },

    isJsonString(string) {
      if (string == null) return false;
      try {
        JSON.parse(string);
      } catch (e) {
        return false;
      }
      return true;
    },

    sortChange(state) {
      this.$store.commit("sortFilter/SET_SORT_STATE", state);
    },
    openVehiclePreview(id) {
      this.selectedVehicleId = id;
      this.$emit("set-noscroll", true);
    },
    closeVehiclePreview() {
      this.selectedVehicleId = 0;
      this.$emit("set-noscroll", false);
    }
  }
};
</script>

<style lang="scss">
.content-wrapper {
  .list-items-header {
    width: calc(100vw - 200px - 96px - 16px);
  }
  &--slim-nav {
    .list-items-header {
      width: calc(100vw - 88px - 96px - 16px);
    }
  }
}
.publications-view {
  display: inline-block;
  height: 100%;
  .list-items {
    padding: 24px 24px 0 24px;
    height: auto;
    &-header {
      position: sticky;
      left: 24px;
      display: grid;
      grid-template-columns: 50% 50%;
      margin-bottom: 0.75rem;
      z-index: 3;
    }
    &-filters {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    &-content {
      border: none;
      overflow: scroll;
      max-height: calc(100vh - 163px);
      margin: 0 -24px -24px -24px;
    }
  }
  .ci-publications {
    &__header {
      position: sticky;
      top: -24px;
      width: 100%;
      background: $grey-1;
      z-index: 2;
      overflow: visible;
      display: inline-flex;
      flex-wrap: nowrap;
      border-bottom: 1px solid $grey-4;
      .ci-listing-item {
        display: inline-flex;
        position: relative;
        height: 80px;
        width: 102px;
        max-width: 102px;
        flex: 0 0 102px;
        margin: 7px 0 14px 0;
        flex-direction: column;
        & > span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          transform-origin: left top;
        }
        &__name {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: $grey-9;
          transform: translateX(32px) translateY(66px) rotate(-45deg);
          transition: color 0.3s ease-in-out;
          cursor: pointer;
          &:hover {
            color: $blue-6;
          }
        }
        &__company {
          font-size: 12px;
          line-height: 17px;
          color: $grey-7;
          transform: translateX(55px) translateY(48px) rotate(-45deg);
        }
        &--spacer {
          width: 400px;
          flex: 0 0 400px;
          background: #fff;
          position: sticky;
          left: 24px;
          z-index: 3;
          &::before {
            content: "";
            background: inherit;
            position: absolute;
            top: -1px;
            bottom: -1px;
            left: -50px;
            width: 50px;
          }
        }
      }
    }
    &__content {
      overflow: visible;
      margin-top: -4px;
      height: 100%;
      .ci-vehicle-items {
        display: inline-block;
        min-width: 100%;
      }
    }
    &__empty-state {
      &--fixed-width {
        max-width: calc(100vw - 296px);
      }
      &--margin {
        margin-top: 104px;
      }
    }
  }
}
</style>
