<template>
  <RdxModal class="ci-vehicle-preview" @close-modal="closeVehiclePreview">
    <div v-if="loading" class="ci-vehicle-preview__loader">
      <RdxLoader size="medium" />
    </div>
    <template #header>
      <span>
        {{ vehicle.nameplate_brand_name }}
        {{ vehicle.nameplate_name }}
      </span>
    </template>
    <div v-if="!loading" class="ci-vehicle-preview__content">
      <CiVehicleMetadata
        :created-by="vehicle.created_by"
        :created-at="vehicle.created_at"
        :updated-at="vehicle.updated_at"
        class="mt-0 mb-4"
      />
      <CiVehicleDataTable :vehicle="vehicle" columns />
      <CiVehicleImages :images="$__photosList" class="mb-4" />
      <CiVehicleVideo
        v-if="vehicle.video_url"
        :video-url="vehicle.video_url"
        class="mb-4"
      />
      <CiVehicleDescription
        v-if="vehicle.long_description"
        :description="vehicle.long_description"
        class="mb-4"
      />
      <CiVehicleFeaturesList :vehicle="vehicle" />
      <CiVehicleAttachment
        v-if="vehicle.attachment_url"
        :attachment-url="vehicle.attachment_url"
      />
      <CiVehicleMap :google-maps-query="googleMapsQuery" />
    </div>
  </RdxModal>
</template>

<script>
import GoogleMapsQueryMixin from "@/mixins/google-maps-query-mixin";
import PhotosListMixin from "@/mixins/photos-list-mixin";

import { RdxModal, RdxLoader } from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiVehicleDataTable from "@/components/CiVehicleDataTable/CiVehicleDataTable";
import CiVehicleImages from "@/components/CiVehicleImages/CiVehicleImages";
import CiVehicleFeaturesList from "@/components/CiVehicleFeaturesList/CiVehicleFeaturesList";
import CiVehicleAttachment from "@/components/CiVehicleAttachment/CiVehicleAttachment";
import CiVehicleMetadata from "@/components/CiVehicleMetadata/CiVehicleMetadata";
import CiVehicleDescription from "@/components/CiVehicleDescription/CiVehicleDescription";
import CiVehicleVideo from "@/components/CiVehicleVideo/CiVehicleVideo";
import CiVehicleMap from "@/components/CiVehicleMap/CiVehicleMap";
import { deserialise } from "kitsu-core";

export default {
  name: "CiVehiclePreview",

  components: {
    RdxModal,
    RdxLoader,
    CiVehicleImages,
    CiVehicleDataTable,
    CiVehicleFeaturesList,
    CiVehicleAttachment,
    CiVehicleMetadata,
    CiVehicleDescription,
    CiVehicleVideo,
    CiVehicleMap
  },

  mixins: [GoogleMapsQueryMixin, PhotosListMixin],
  props: {
    vehicleId: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      loading: true,
      vehicle: {}
    };
  },

  created() {
    this.getVehicleData();
  },
  methods: {
    closeVehiclePreview() {
      this.$emit("close-modal");
    },
    getVehicleData() {
      this.$http
        .get(`vehicles/${this.vehicleId}?include=photos,owner`)
        .then(response => {
          deserialise(response.data);
          this.vehicle = response.data.data;
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss">
.ci-vehicle-preview {
  .rdx-modal-box {
    width: 800px;
    max-width: 90vw;
  }
  .rdx-modal-box__content {
    max-height: 75vh;
  }
  &__content {
    padding: 24px;
  }
  &__image {
    flex-basis: 640px;
  }
  &__loader {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
