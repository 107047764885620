<template>
  <v-popover
    class="ci-vehicle-details-tooltip"
    trigger="hover"
    popover-class="tooltip-white"
  >
    <RdxIcon icon="ri-information-fill" class="tooltip-target" />
    <template slot="popover">
      <!-- version -->
      <div class="tooltip-item">
        <div class="tooltip-item__label">
          {{ $t("publications.version-label") }}:
        </div>
        <div class="tooltip-item__value" data-cy="tooltip-version">
          {{ version }}
        </div>
      </div>

      <!-- state -->
      <div class="tooltip-item">
        <div class="tooltip-item__label">
          {{ $t("publications.state-label") }}:
        </div>
        <div class="tooltip-item__value" data-cy="tooltip-state">
          {{ $t(`global.states.${state}`) }}
        </div>
      </div>

      <!-- sale price -->
      <div v-if="salePrice" class="tooltip-item">
        <div class="tooltip-item__label">
          {{ $t("publications.sale-price-label") }}:
        </div>
        <div class="tooltip-item__value" data-cy="tooltip-sale-price">
          {{ $format.price(salePrice, ownerLocale, currency) }}
        </div>
        <RdxIcon
          v-if="priceHidden"
          class="ri-eye-off-line tooltip-item__hidden-icon"
        />
      </div>

      <!-- price -->
      <div class="tooltip-item">
        <div class="tooltip-item__label">
          {{ $t("publications.price-label") }}:
        </div>
        <div class="tooltip-item__value" data-cy="tooltip-price">
          {{ $format.price(price, ownerLocale, currency) }}
        </div>
        <RdxIcon
          v-if="priceHidden && !salePrice"
          class="ri-eye-off-line tooltip-item__hidden-icon"
        />
      </div>

      <!-- lease price -->
      <div v-if="leasePrice" class="tooltip-item">
        <div class="tooltip-item__label">
          {{ $t("publications.lease-label") }}:
        </div>
        <div class="tooltip-item__value" data-cy="tooltip-lease-price">
          {{ $format.price(leasePrice, ownerLocale, currency) }}
        </div>
      </div>
    </template>
  </v-popover>
</template>

<script>
import { RdxIcon } from "@raffine/rdx-ui/lib/rdx-ui.umd";

export default {
  name: "CiVehicleDetailsTooltip",

  components: {
    RdxIcon
  },

  props: {
    currency: {
      required: true,
      type: String
    },
    ownerLocale: {
      required: true,
      type: String
    },
    version: {
      required: true,
      type: String
    },
    state: {
      required: true,
      type: String
    },
    salePrice: {
      type: String,
      default: undefined
    },
    price: {
      required: true,
      type: String
    },
    leasePrice: {
      type: String,
      default: undefined
    },
    priceHidden: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.ci-vehicle-details-tooltip {
  color: $caption-blue;
  transition: color 0.3s ease;
  vertical-align: middle;
  display: inline;
  &:hover {
    color: $blue-5;
  }
}

.tooltip.popover.tooltip-white {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  .tooltip-arrow {
    border-color: #fff;
  }
  .tooltip-inner,
  .popover-inner {
    background-color: #fff;
    color: $grey-9;
    font-size: 14px;
    line-height: 22px;
    padding: 5px 8px;
  }
  .tooltip-item {
    &__label,
    &__value {
      display: inline;
    }
    &__label {
      color: $grey-7;
    }
    &__value {
      padding-left: 3px;
    }
    &__hidden-icon {
      margin-left: 5px;
      color: $grey-7;
      transform: translateY(3px);
      display: inline-block;
    }
  }
}
</style>
