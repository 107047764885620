<template>
  <div class="ci-publication-row">
    <div class="ci-publication-row__vehicle-container">
      <div class="ci-vehicle-details-card">
        <CiCardImage
          class="ci-publication-row__image"
          :cover-photo="coverPhoto"
          icon-size="26"
        />
        <div class="ci-vehicle-details-card__details">
          <div
            class="ci-vehicle-details-card__link"
            @click="openVehiclePreview"
          >
            <CiHeading :level="2" class="ci-vehicle-details-card__model">
              {{ brandName }}
              <template v-if="nameplateName">
                {{ nameplateName }}
              </template>
            </CiHeading>
          </div>

          <CiSpec :spec="version">
            <CiHeading :level="3" class="ci-vehicle-details-card__version">
              {{ version }}

              <CiVehicleDetailsTooltip
                :currency="currency"
                :owner-locale="ownerLocale"
                :version="yearWithVersion"
                :state="state"
                :sale-price="salePrice"
                :price="price"
                :lease-price="lease"
                :price-hidden="priceHidden"
              />
            </CiHeading>
          </CiSpec>

          <CiSpec :spec="vin" class="ci-vehicle-details-card__vin ci-spec--12">
            {{ $t("publications.vin-label") }}: {{ vin }}
          </CiSpec>
        </div>
      </div>
    </div>

    <div
      v-for="(list, index) in selectedLists"
      :key="`vehicle-${vehicleId}_list-${index}`"
      class="ci-list-table__column"
      :class="`column-${index}`"
      @mouseover="$emit('highlight', index)"
      @mouseleave="$emit('highlight', null)"
    >
      <CiPublicationCheckbox
        :value="includeList(list.id)"
        :loading="listingIsPending(list.id)"
        @input="updateVehicleListings($event, list.id, vehicleId)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CiCardImage from "@/components/molecules/CiCardImage";
import CiHeading from "@/components/atoms/CiHeading";
import CiSpec from "@/components/atoms/CiSpec";
import CiVehicleDetailsTooltip from "@/components/atoms/CiVehicleDetailsTooltip";
import CiPublicationCheckbox from "@/components/atoms/CiPublicationCheckbox";

export default {
  name: "CiPublicationRow",

  components: {
    CiCardImage,
    CiHeading,
    CiSpec,
    CiVehicleDetailsTooltip,
    CiPublicationCheckbox
  },

  props: {
    vehicleId: {
      type: Number,
      default: undefined
    },
    brandName: {
      type: String,
      default: undefined
    },
    nameplateName: {
      type: String,
      default: undefined
    },
    vin: {
      type: String,
      default: undefined
    },
    version: {
      type: String,
      default: undefined
    },
    productionYear: {
      type: Number,
      default: undefined
    },
    selectedLists: {
      type: Array,
      default: () => []
    },
    coverPhoto: {
      type: String,
      default: undefined
    },
    state: {
      type: String,
      default: undefined
    },
    salePrice: {
      type: String,
      default: undefined
    },
    price: {
      type: String,
      default: undefined
    },
    lease: {
      type: String,
      default: undefined
    },
    priceHidden: {
      type: Boolean,
      default: false
    },
    currency: {
      type: String,
      default: undefined
    },
    ownerLocale: {
      required: true,
      type: String
    }
  },

  data() {
    return {
      pending: []
    };
  },

  computed: {
    yearWithVersion() {
      return this.productionYear + " " + this.version;
    },
    ...mapGetters({
      getById: "listings/getById"
    })
  },

  methods: {
    includeList(id) {
      let vehiclesRelations = this.getById(id)["relationships"]["vehicles"][
        "data"
      ];
      let vehicleIds = vehiclesRelations.map(el => el["id"]);
      return Boolean(vehicleIds.find(el => el === this.vehicleId.toString()));
    },

    async updateVehicleListings(state, listingId, vehicleId) {
      this.pending.push(listingId);
      try {
        const ACTION_NAME = state
          ? "listings/addVehicleToListing"
          : "listings/removeVehicleToListing";
        const response = await this.$store.dispatch(ACTION_NAME, {
          listingId,
          vehicleId
        });

        const ACTION_TYPE = state ? "added" : "removed";
        this.$toast.open({
          message: this.$t(`publications.vehicle-${ACTION_TYPE}-successfull`, {
            title: response.attributes.title
          }),
          type: state ? "success" : "info"
        });
      } catch (error) {
        console.error("CI :: " + error);
        this.$toast.open({
          message: `Error: ${error}`,
          type: "error"
        });
      } finally {
        this.$delete(this.pending, this.pending.indexOf(listingId));
      }
    },

    listingIsPending(listingId) {
      return this.pending.includes(listingId);
    },

    openVehiclePreview() {
      this.$emit("open-preview");
    }
  }
};
</script>

<style lang="scss">
.ci-publication-row {
  background-color: #fff;
  border-bottom: 1px solid $grey-4;
  transition: background-color 0.3s ease-in-out;
  display: flex;
  flex-wrap: nowrap;
  &:hover {
    background-color: $grey-2;
  }
  &__vehicle-container {
    width: 400px;
    min-width: 400px;
    position: sticky;
    left: 24px;
    background: inherit;
    z-index: 1;
    &::before {
      content: "";
      background: #fff;
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -50px;
      width: 50px;
    }
  }
  &__image {
    flex-basis: 125px;
    height: 70px;
  }
  .ci-vehicle-details-card {
    padding: 15px;
    display: flex;
    position: relative;
    &__link {
      cursor: pointer;
    }
    &__model,
    &__version,
    &_vin {
      margin: 2px 0;
    }
    &__model {
      display: inline-block;
      &:hover {
        color: $blue-6;
      }
    }
  }
  .ci-list-table__column {
    display: flex;
    align-items: center;
    width: 102px;
    flex: 0 0 102px;
    position: relative;
    background: transparent;
    transition: background 0.5s ease;
    &:hover {
      background: $grey-3 !important;
    }
  }
}

.c-green-6 {
  color: $green-6;
}
.c-blue-6 {
  color: $blue-6;
}
</style>
