var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
    content: _vm.value
      ? _vm.$t('publications.remove-from-list-tooltip')
      : _vm.$t('publications.add-to-list-tooltip'),
    delay: { show: 100, hide: 100 },
    hideOnTargetClick: false
  }),expression:"{\n    content: value\n      ? $t('publications.remove-from-list-tooltip')\n      : $t('publications.add-to-list-tooltip'),\n    delay: { show: 100, hide: 100 },\n    hideOnTargetClick: false\n  }"}],staticClass:"ci-publication-checkbox",class:{
    'ci-publication-checkbox--inactive': _vm.value === false,
    'ci-publication-checkbox--active': _vm.value === true,
    'ci-publication-checkbox--disabled': _vm.loading === true
  },on:{"mouseenter":function($event){_vm.hovered = true},"mouseleave":function($event){_vm.hovered = false},"click":_vm.onClick}},[_c('RdxIcon',{attrs:{"icon":_vm.checkboxIcon}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }