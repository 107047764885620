<template>
  <div
    v-tooltip="{
      content: value
        ? $t('publications.remove-from-list-tooltip')
        : $t('publications.add-to-list-tooltip'),
      delay: { show: 100, hide: 100 },
      hideOnTargetClick: false
    }"
    class="ci-publication-checkbox"
    :class="{
      'ci-publication-checkbox--inactive': value === false,
      'ci-publication-checkbox--active': value === true,
      'ci-publication-checkbox--disabled': loading === true
    }"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
    @click="onClick"
  >
    <RdxIcon :icon="checkboxIcon" />
  </div>
</template>

<script>
import { RdxIcon } from "@raffine/rdx-ui/lib/rdx-ui.umd";

export default {
  name: "CiPublicationCheckbox",

  components: {
    RdxIcon
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      hovered: false
    };
  },

  computed: {
    checkboxIcon() {
      if (this.loading) return "ri-loader-5-line";
      if (this.hovered) {
        return this.value
          ? "ri-indeterminate-circle-fill"
          : "ri-add-circle-fill";
      }
      return this.value
        ? "ri-checkbox-circle-fill"
        : "ri-checkbox-blank-circle-line";
    }
  },

  methods: {
    onClick() {
      if (this.loading) return;
      this.$emit("input", !this.value);
    }
  }
};
</script>

<style lang="scss">
.ci-publication-checkbox {
  display: flex;
  width: 26px;
  height: 26px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  line-height: 26px;
  cursor: pointer;
  transition: color 0.3s ease;
  &--inactive {
    color: $grey-5;
  }
  &--active {
    color: $green-6;
  }
  &--disabled {
    cursor: default;
    color: $blue-6;
    i {
      animation: loading-spin 1s infinite;
    }
  }
  &:hover {
    color: $blue-6;
  }
}

@keyframes loading-spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
